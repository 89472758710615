import { createApp } from 'vue'
import { VERSION, COMMIT } from './version'

// Boot
console.log(`T3Admin ${VERSION} (${COMMIT})`)
console.log(`MODE: ${import.meta.env.MODE}`)

import pinia from './stores'
import router from './router'

import setupMixins from './mixins'
import setupGlobals from './globals'

import setupDirectives from './helpers/directives'
import './helpers/filters'
import setupFormatters from './helpers/formatters'

import setupComponents from './components'

import App from './App.vue'

const app = createApp(App)
app.use(router)
app.use(pinia)

setupMixins(app)
setupGlobals(app)
setupComponents(app)
setupFormatters(app)
setupDirectives(app)

// Initialize Sentry
//import { createSentry } from './plugins/sentry'
// import { applyStyles } from '@popperjs/core'
//createSentry(app, router)

// Initialize App
import { useAuthStore } from '@/stores/auth'
const authStore = useAuthStore(pinia)
authStore.initAuth()

import { useRoutesStore } from '@/stores/routes'
const routesStore = useRoutesStore(pinia)
routesStore.initialize(router)

app.mount('#app')
